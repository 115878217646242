<template>
  <v-dialog
    :value="mostrar"
    @click:outside="$emit('fechar')"
  >
    <v-card>
      <v-toolbar
        color="grey lighten-3"
        flat
        dense
      >
        <v-toolbar-title>
          Mapa
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            text
            small
            @click="$emit('fechar')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div id="wrapper">
        <GmapMap
          id="map"
          :options="{
            streetViewControl: false,
            fullscreenControl: false
          }"
          :center="coordenada"
          :zoom="17"
        >
          <GmapMarker
            :position="coordenada"
          />
        </GmapMap>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="sass" scoped>
#wrapper
  position: relative

#map
  width: 100%
  height: 500px
  display: absolute

#over_map
  position: absolute
  top: 0,
  left: 0,
  width: 100%
  z-index: 99
  text-align: right
</style>

<script>
  import { gmapApi } from 'vue2-google-maps'

  export default {

    props: {
      mostrar: {
        type: Boolean,
        default: false,
      },
      latitude: {
        type: Number,
        default: 0,
      },
      longitude: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {

      }
    },

    computed: {
      google: gmapApi,
      coordenada () {
        return {
          lat: this.latitude,
          lng: this.longitude,
        }
      },
    },

    methods: {

    },

  }
</script>
